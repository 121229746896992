@import "../button/button.scss";

.ml-dropdown {
  display: inline-flex;
  flex-shrink: 0;
  .dropdown-toggle {
    > svg {
      --icon-size: 1.5rem;
      &[class*="chevron-down"] {
        stroke-width: 3px;
        margin-right: -4px;
        margin-left: 4px;
      }
    }
    &.outline {
      padding: 0.75rem 1.75rem !important;
      font-size: 14px !important;
      --border-color: var(--theme-indigo);
      --text-color: var(--theme-indigo);
      gap: 0.5rem;

      &:hover,
      &:focus {
        --text-color: var(--theme-indigo);
        border-color: var(--theme-indigo);
        background-color: rgb(140, 98, 255, 0.1);
      }
      &:focus,
      &:active {
        box-shadow: 0 0 0 0.25rem rgba(140, 98, 255, 0.1);
        outline: none;
        color: var(--theme-indigo);
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &.blue-outline {
        --border-color: var(--badge-blue);
        --text-color: var(--badge-blue);
        &:hover,
        &:focus {
          --text-color: var(--badge-blue);
          border-color: var(--badge-blue);
          background-color: rgb(#5c98df, 0.1);
        }
        &:focus,
        &:active {
          box-shadow: 0 0 0 0.25rem rgba(#5c98df, 0.1);
          color: var(--badge-blue);
        }
      }

      &.yellow-outline {
        --border-color: var(--theme-yellow);
        --text-color: var(--theme-yellow);
        &:hover,
        &:focus {
          --text-color: var(--theme-yellow);
          border-color: var(--theme-yellow);
          background-color: rgb(#e8b500, 0.1);
        }
        &:focus,
        &:active {
          box-shadow: 0 0 0 0.25rem rgba(#e8b500, 0.1);
          color: var(--theme-yellow);
        }
      }

      &.sm {
        padding: 0.7rem 1.5rem !important;
        font-size: 14px !important;
      }

      &.fill-bg {
        background-color: var(--theme-bg);
      }
    }
  }
  .ml-dropdown-menu {
    flex-direction: column;
    gap: 0rem;
    padding: 0.5rem;
    border-radius: var(--border-radius, 8px);
    background-color: white;
    box-shadow: 0 2px 4px 0 rgb(1 1 2 / 5%);
    &.show {
      display: flex !important;
    }
    .dropdown-item {
      display: flex;
      gap: 0.75rem;
      align-items: center;
      justify-content: flex-start;
      padding: 0.75rem 0.75rem;
      font-size: 14px;
      border-radius: var(--border-radius, 8px);
      color: rgba($color: #040410, $alpha: 0.7);
      cursor: pointer;
      transition: ease all 0.3s;
      min-width: 190px;
      &:hover {
        background-color: rgba(216, 216, 216, 0.2);
        color: var(--theme-text);
        text-decoration: none;
      }
      svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
}
