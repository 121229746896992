@import "../../../assets/styles/variables";

.ml-badge {
  --badge-color: #11103c;
  --badge-bg-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 1.5rem;
  border-radius: 999px;
  font-weight: 500;
  color: var(--badge-color);
  background-color: var(--badge-bg-color);
  border: 1px solid rgba(#11103c, 0.1);
  font-size: 14px;
  line-height: 1;
  cursor: default;
  text-transform: capitalize;
  font-family: var(--font-text);

  &.spam,
  &.inbox,
  &.missing {
    border: 0;
  }

  &.inbox {
    color: #008c53;
    background-color: #bbf7de;
  }

  &.missing {
    color: #ee7f0c;
    background-color: #ffe8c2;
  }

  &.spam {
    color: #d83b22;
    background-color: #ffc1c2;
  }

  &.pro {
    color: #5c98df;
    border-color: #5c98df;
  }

  &.personal,
  &[class*="pers"] {
    color: #da9e53;
    border-color: #da9e53;
  }

  &.other,
  &.pending {
    color: #da9e53;
    border-color: #da9e53;
  }

  &.missing {
    color: #5d22d2;
    background-color: #ece4fa;
  }
}
