@font-face {
  font-family: "TWK Lausanne";
  src: url("TWKLausanne-500.woff2") format("woff2"), url("TWKLausanne-500.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TWK Lausanne";
  src: url("TWKLausanne-400.woff2") format("woff2"), url("TWKLausanne-400.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("SFProDisplay-Semibold.woff2") format("woff2"), url("SFProDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("SFProDisplay-Thin.woff2") format("woff2"), url("SFProDisplay-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("SFProDisplay-Regular.woff2") format("woff2"), url("SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("SFProDisplay-Medium.woff2") format("woff2"), url("SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("SFProDisplay-Light.woff2") format("woff2"), url("SFProDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-title: "TWK Lausanne", sans-serif;
  --font-text: "SFProDisplay", sans-serif;
}
