.ml-navbar {
  width: 100%;
  background-color: #11103b !important;
  position: fixed;
  top: 0;
  z-index: 99;
  border-radius: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  .ml-navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 60px;
    width: 100%;
    background-color: #11103b !important;

    a {
      cursor: pointer;
      img {
        height: 44px;
      }
    }
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .ml-navbar .ml-navbar-header {
    justify-content: center;
    align-items: center;
    height: 50px;

    a img {
      height: 40px;
    }

    > .button {
      display: none !important;
    }
  }
}
