@import "../../../assets/styles/variables";

.ml-card {
  width: 100%;
  display: block;
  --card-padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--card-border-color);
  padding: var(--card-padding);
  overflow: hidden;
}
