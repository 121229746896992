@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.ml-email-info {
  @include flex-items-center;
  gap: 1rem;
  width: auto;
  flex-grow: 1;
  flex-shrink: 0;

  .ml-email-info-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;

    p,
    span {
      margin: 0;
      font-size: 16px;
      line-height: 1;
      color: var(--theme-text);
      font-weight: 400;
    }

    p {
      cursor: pointer;

      @media (max-width: 480px) {
        font-size: 15px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: 400px) {
        max-width: 190px;
      }
    }

    span {
      font-weight: 500;
      opacity: 0.5;
      font-size: 14px;
    }

    > span {
      &:last-child {
        display: none;

        @media (min-width: 861px) {
          display: block;
        }
      }
    }
  }

  .ml-email-info-icon {
    border: 1px solid rgba(221, 221, 221, 0.433);
    --icon-radius: 999px;
    --icon-size: 48px;
    --icon-img-size: 26px;
    img {
      width: var(--icon-img-size);
      height: var(--icon-img-size);
    }
    box-shadow: 0px 1px 1px rgba(221, 221, 221, 0.6);
  }

  .ml-email-more-info {
    display: flex;
    align-items: center;
    gap: 0rem;
    font-size: 14px;
    line-height: 1;
    color: #04041080;
    span {
      font-weight: 500;
      opacity: 0.5;
      font-size: 14px;
    }
    .ml-email-info-dot {
      margin-top: -3px;
      margin-bottom: -3px;
    }

    @media (min-width: 861px) {
      display: none;
    }

    .ml-email-inbox-type,
    .ml-email-inbox-type + .ml-email-info-dot {
      @media (min-width: 631px) {
        display: none;
      }
    }

    @media (max-width: 480px) {
      > .ml-email-info-dot:last-of-type,
      > span:last-of-type {
        display: none;
      }
    }

    .ml-email-inbox-type {
      opacity: 1;
      &.perso {
        color: var(--theme-yellow);
      }
      &.pro {
        color: var(--badge-blue);
      }
    }
  }
}

@media (max-width: 630px) {
  .spam-results-modal tr td {
    padding: 12px !important;
  }
}

@media (max-width: 480px) {
  .table-results tr td.email-info-td {
    max-width: 230px;
    padding-right: 8px !important;
  }
  .ml-email-info .ml-email-info-icon {
    --icon-size: 36px;
    --icon-img-size: 18px;
  }
  .ml-email-info .ml-email-more-info .ml-email-info-dot {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .table-results .ml-badge {
    padding: 0.5rem 1.25rem !important;
  }
}
