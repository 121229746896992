// Components

@import "./variables";

// modal styles
.modal-backdrop {
  background: #04041080;
}

.modal {
  backdrop-filter: blur(4px);

  .modal-content {
    border-radius: 16px !important;
    border: 0 !important;
    box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%);
    padding: 2rem;
  }

  .modal-body {
    h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
      color: var(--theme-text);
      font-family: var(--font-title);
      margin: 1rem 0;
      text-align: left;

      &:empty {
        display: none;
      }
    }

    .from {
      font-family: var(--font-title);

      b {
        font-weight: 600;
      }
    }
  }
}

.ml-blur-bg-container {
  .ml-blur-bg {
    background: #04041032;
    position: fixed;
    height: calc(100vh - var(--blur-spacing-top));
    top: var(--blur-spacing-top, 0);
    left: 0;
    right: 0;
    z-index: 10;
    backdrop-filter: blur(var(--blur-value));
  }

  .ml-blur-content {
    position: fixed;
    height: calc(100vh - var(--blur-spacing-top));
    top: var(--blur-spacing-top, 0);
    left: 0;
    right: 0;
    z-index: 11;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    padding: 10rem 1.5rem 0;

    @media (max-width: 700px) {
      padding-top: 5rem;
    }

    > div,
    .requested-type-selector {
      z-index: 15;
      border-radius: 16px;
      border: 0;
      box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%);
      padding: 3rem 4rem;
      max-width: var(--content-width, 700px);
      margin: 0 auto;
      background: #ffffff;
      color: #040410;
      overflow: hidden;
    }

    .requested-type-selector {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      p {
        font-size: 16px;
        margin: 0;
        font-weight: 500;
      }
      .requested-type-options {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      .note {
        font-weight: 400;
        opacity: 0.8;
        display: block;
        font-size: 14px;

        > b {
          font-weight: 500;
        }
      }
    }
  }
}
