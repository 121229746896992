@import "../../../assets/styles/variables";

.stats-container {
  border-radius: 16px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  flex-shrink: 0;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;

    @media (min-width: 540px) {
      .stat-blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;

        .stat-block {
          &:last-child {
            border-right: 0;
            padding-left: 20px;
          }
          &:first-child {
            border-right: 1px solid #0404100f;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  margin: 0 0 1rem;
  line-height: 1.2;
  color: var(--theme-text);
  max-width: 250px;
}

.stat-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 0;

  @media (min-width: 801px) {
    &:last-child {
      margin-bottom: 0;
      border-top: 1px solid #0404100f;
    }
  }
}

.stat-label {
  font-size: 14px;
  color: #040410a1;
  font-weight: 500;
  margin: 0 0 5px;
}

.stat-value {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  font-size: 80px;

  &.blue {
    color: #60a5fa;
    background: linear-gradient(96.52deg, #6dbdfa 0%, #8980f9 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.green {
    color: #34d399;
    background: linear-gradient(96.52deg, #20ec86 0%, #17a35d 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
