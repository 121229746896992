h1,
h2 {
  color: #11103b;
}

.th-text-center {
  text-align: center !important;
}

.pointer {
  cursor: pointer;
}

.break-word {
  word-break: break-all;
}

.line-break {
  & {
    line-break: anywhere;
  }
}

.modal-content .headers {
  font-size: 13px;
}

.headers table tr:first-child td {
  border-top: 0 !important;
}

.cursor-pointer {
  & {
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .no-padding-right.col-md-9 {
    padding-right: 15px !important;
    padding-left: 0;
  }

  .hide-responsive {
    display: none !important;
  }

  .rp-right-margin {
    margin-right: 15px !important;
  }

  .ct {
    width: 100% !important;
  }

  .sub-nav .ct ul {
    & {
      padding: 0;
    }
    a {
      width: 100%;
      float: left;
      text-align: left;
    }
  }
  .checkups {
    & {
      padding-right: 15px;
    }
  }

  .insight-content {
    & {
      height: auto !important;
    }
  }
}

.upgrade-spamchecker.waiting.card {
  & {
    margin-top: 80px;
  }
  .loader {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) {
  .container,
  .ct {
    width: 100% !important;
    max-width: 1180px;
  }
}

.blue-btn {
  & {
    background-color: #11103b !important;
    color: #fff !important;
  }
}

.no-padding-right {
  padding-right: 0 !important;
}

.loader {
  margin-top: 100px;
}

.btn-header {
  margin-left: 20px;
}

.share-txt {
  & {
    float: right;
    padding: 38px 10px 5px;
    font-weight: 500;
    color: #11103b;
  }
}

.alert-orange {
  & {
    background: #ff9d54;
    color: #fff;
    padding: 10px;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.share-link {
  & {
    background-color: #fff !important;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: #11103b !important;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    margin: 25px 0 0;
    float: right;
  }
  .code,
  .copy {
    padding: 12px;
  }
  .copy {
    background: #f8f9fb;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #ccc;
    border-top-right-radius: 8px;
    color: #10113b;
    float: left;
    font-size: 14px;
    margin-left: 0;
    padding: 10px 16px;
  }
}

.card {
  border: none;
  box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%);
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 14px;
  flex-direction: unset;
}

.inbox-type-label {
  & {
    background: #e9e9e9;
    padding: 5px 12px;
    border-radius: 10px;
    margin-top: 8px;
    float: left;
    margin-left: 10px;
    color: #5c98df !important;
    background-color: #e2edf9 !important;
    border-color: #5c98df !important;
    border: 1px solid;
  }
  &.perso {
    & {
      color: #da9e53 !important;
      background-color: #fcebcc !important;
      border-color: #da9e53 !important;
    }
  }
}

.green-bck {
  background-color: #26c180;
  border: 0;
}

.upgrade-spamchecker {
  & {
    color: #fff;
    background: #11103b;
    padding: 23px;
  }

  .green-bck {
    background-color: #26c180;
    border: 0;
  }
  h2 {
    margin: 0 0 15px !important;
    color: #fff;
  }

  .col-md-12 {
    & {
      padding-left: 0;
    }
  }

  p {
    margin: 0 0 10px;
  }

  .btn-primary {
    margin: 12px 0px 0px;
  }
}

.fa-info-circle {
  cursor: pointer;
}

.template-accounts-selector {
  & {
    padding: 0;
    margin: 10px 0;
    width: 100%;
  }
  li {
    & {
      list-style: none;
      float: left;
      padding: 10px 19px;
      color: #6f7588;
      background-color: #f4f6f9;
      border-radius: 29px;
      margin: 5px;
      font-weight: 500;
      cursor: pointer;
    }
    &:hover {
      color: #6f7588;
      background-color: #dbe2ec;
    }
    &.selected {
      color: #fff;
      background-color: #3d46fb;
    }
  }
}

p.insight-content {
  & {
    height: 115px;
    padding: 15px 0px;
  }
}

.spamtests-loading {
  & {
    margin-top: 25px;
    margin-left: 15px;
  }
  &.top-margin {
    margin-top: 33px;
  }
}
.templates {
  .empty {
    img {
      width: 50%;
    }
  }
}

.ar-nav {
  & {
    color: #6f7588;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    padding: 0px 25px;
    padding-left: 0;
  }
  a {
    & {
      margin: 0 5px;
    }
    &:first-child {
      margin-left: 15px;
    }
  }
}

.top-margin {
  margin-top: 50px;
}

.request-action {
  & {
    font-size: 15px;
  }
  .col-md-10 {
    padding-top: 16px;
  }
  img {
    width: 65px;
  }
  .start-email {
    margin-left: 10px;
  }
  .txt {
    float: left;
    margin-top: 5px;
  }
  .btn {
    margin-top: -9px;
  }
  .loading {
    .loader {
      margin-right: 15px;
      margin-top: -9px;
    }
    &.txt {
      width: 100%;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.start-email {
  & {
    background: #00000012;
    display: initial;
    padding: 5px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 500;
  }
}

.templates {
  .score-change {
    .increase {
      & {
        color: #1ac797;
        background: transparent !important;
      }
      &.bad {
        color: #ff1d51;
      }
      &.grey {
        color: #6f7588;
      }
    }
  }
  .spam-result-item {
    &:nth-child(2) {
      margin-top: 20px !important;
    }
  }
  .increase {
    & {
      border-radius: 17px !important;
      margin-top: 18px !important;
    }
  }
  .dropdown-menu-a {
    & {
      padding: 15px;
      width: 325px;
      border-radius: 11px;
      border: 1px solid #e0e5e9;
      border-radius: 10px;
      box-shadow: 0 15px 30px 0 #d6d6d6a8;
      margin-left: -51px !important;
      margin-top: 10px !important;
    }
    a {
      width: 100%;
      float: left;
    }
    a:hover {
      text-decoration: none;
    }
    &:before,
    &:after {
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      content: "";
      display: inline-block;
      left: 30px;
      position: absolute;
      border-bottom: 14px solid #e0e5e9;
      top: -15px;
      margin-right: 131px;
    }
    &:before,
    &:after {
      left: unset;
      right: 30px;
    }
    &:after {
      border-bottom: 14px solid #fff;
      top: -14px;
    }
    ul {
      & {
        list-style: none;
        padding: 0;
      }
      li {
        & {
          color: #6f7588;
          font-weight: 500;
          padding: 13px;
          font-size: 16px;
          margin: 8px 0;
          cursor: pointer;
          border-radius: 10px;
        }
        a {
          color: #6f7588;
          font-weight: 500;
          font-size: 16px;
        }
        &:hover {
          background-color: #f4f6f9;
        }
        i {
          margin-right: 9px;
        }
      }
    }
  }
  .tit {
    & {
      color: #18164c;
      padding-top: 6px;
      cursor: pointer;
    }
    .subject {
      color: #18164c;
      font-weight: 500;
      width: 100%;
      float: left;
    }
    p {
      font-size: 15px;
      opacity: 0.6;
      margin-top: 6px;
      float: left;
    }

    &:hover {
      .preview-hide {
        display: block;
      }
      .test-from-email {
        display: none;
      }
    }

    .preview-hide {
      display: none;
    }
  }
  .template-name {
    & {
      margin-top: 4px;
    }
  }
  .running-on {
    & {
      padding: 11px 5px;
    }
  }
  .accounts-selector {
    & {
      cursor: pointer;
    }
  }
  .score {
    & {
      background: #ff1d51;
      margin-top: 6px;
      display: inline-block;
      padding: 4px 8px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
    &.running,
    &.average {
      background: #f7bf23;
    }
    &.good {
      background: #1ac797;
    }
  }
  .timeago {
    & {
      margin-top: 10px;
      opacity: 0.8;
    }
  }

  .actions-dropdown {
    & {
      background: none;
      color: #6f7588;
      border: none;
      opacity: none;
      font-size: 16px !important;
      margin-top: 12px;
    }
  }
  .status {
    & {
      margin: 28px 12px 0 0px !important;
    }
    .running {
      box-shadow: 0px 0px 3px 0px #f7bf23;
      background: #f7bf23;
      animation: pulse 2s infinite;
    }
  }
}
.ico-btn {
  & {
    background: none;
    border: none;
    font-size: 26px;
    color: #6f7588;
    opacity: 1;
    margin-right: 20px;
  }
  &:hover {
    opacity: 1;
  }
}

.reputation-modal .modal-content {
  & {
    width: 525px;
  }
}

.spam-results-modal {
  .empty {
    img {
      max-width: 400px;
      margin: 40px 0;
    }
  }
  .modal-content,
  .modal-dialog {
    & {
      max-width: 950px;
    }
  }
  .txt {
    & {
      padding: 20px 0;
    }
  }
  tr {
    td {
      border-top: 1px solid #0000000d !important;
      padding: 14px !important;
    }
    &.INBOX {
      background: #edfffa;
    }
  }
  .badge {
    & {
      background: #ccc;
      padding: 8px 9px;
      font-weight: 500;
      color: #636363;
      font-size: 15px;
      text-transform: capitalize;
      border-radius: 17px;
      padding: 10px 15px;
      margin-top: 6px;
      cursor: pointer;
    }
    &.INBOX {
      background: #bbf7de;
      color: #008c53;
    }
    &.PERSONAL,
    &.UPDATES,
    &.PROMOTION,
    &.categorie {
      background: #ffe8c2 !important;
      color: #ee7f0c !important;
    }
    &.SPAM {
      background: #ffc2c2 !important;
      color: #d83b22 !important;
    }
  }
  .avatar {
    width: 40px;
    margin-right: 10px;
    border-radius: 4px;
    margin-top: 6px;
  }
  .providerbadge {
    & {
      padding: 5px 9px;
      border-radius: 10px;
      text-transform: capitalize;
      margin-top: 8px;
      float: left;
      color: #11103b;
      border: 1px solid #11103b;
    }
    &.outlook {
      color: #0067b8;
      border: 1px solid #0067b8;
    }
    &.yahoo {
      color: #9302c1;
      border: 1px solid #9302c1;
    }
  }

  .running {
    margin-top: 38px;
    font-size: 12px;
    margin-left: 15px;
    margin-right: 10px;
  }
}

.spamtest-free {
  & {
    margin-top: 0;
    margin-bottom: 50px;
  }
  img {
    width: 90px;
  }
  .col-md-10 {
    &,
    .txt {
      margin-top: 0;
    }

    & {
      padding-top: 10px;
    }
  }
}

.inbox-badge-color {
  background: #bbf7de !important;
  color: #008c53 !important;
}
.categorie-badge-color {
  background: #ffe8c2 !important;
  color: #ee7f0c !important;
}
.missing-badge-color {
  background: #ece4fa !important;
  color: #5d22d2 !important;
}
.spam-badge-color {
  background: #ffdae2 !important;
  color: #ff1d51 !important;
}

.folder {
  .score {
    & {
      margin-right: 10px;
      padding: 6px 14px !important;
      border-radius: 17px !important;
      margin-top: 18px !important;
      font-weight: 500;
    }
  }
}

.links-analysis-modal {
  & {
  }
  .modal-dialog,
  .modal-content {
    & {
      max-width: 700px;
    }
  }

  .modal-content {
    & {
      width: 700px;
    }
  }
}

.message-design {
  & {
  }
  h1 {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-left: 10px;
    padding-bottom: 20px;
  }
  .txt {
    padding: 20px;
    padding-left: 10px;
  }
  .from {
    border-bottom: 1px solid #ccc;
    padding: 5px 10px 14px 10px;
  }
}

.table-results {
  & {
    margin-bottom: 0 !important;
  }
  tr:first-child td {
    border-top: 0px !important;
  }
  .account-desc {
    padding-top: 4px;
    display: block;
    font-weight: 500;
  }
  .hour {
    max-width: 153px;
    padding-top: 29px !important;
  }
  .inbox-type {
    & {
      width: 250px;
    }
  }
}

.templates {
  & {
    margin-top: 0 !important;
    padding-right: 0;
  }
  .btn-header {
    margin-top: 25px;
  }

  .inbox-rate-nb {
    font-size: 45px;
    margin: 36px 0 16px;
  }
}

.message-design {
  table {
    td {
      border-top: 0;
    }
  }
}

.test-from-email {
  & {
    background: #00000012;
    display: initial;
    padding: 5px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    float: left;
    color: #6f7588;
    margin-top: 10px;
  }
}

.spam-result-item {
  margin-top: 30px;
}

.no-padding-top {
  padding-top: 0 !important;
}

.second-title {
  float: left;
  margin-bottom: 25px;
}

.load-next-tests {
  & {
    margin-top: 30px;
    float: left;
    width: 100%;
  }
  .load-next-tests-btn {
    & {
      text-align: center;
      background: #d9dfe4;
      border: 0;
      color: #818797;
      font-weight: 500;
      padding: 8px 20px;
      border-radius: 24px;
      opacity: 0.8;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.capitalize {
  & {
    text-transform: capitalize !important;
  }
}

.select-test-type {
  & {
    list-style: none;
    text-align: center;
    display: inline-flex;
  }
  button {
    & {
      float: left;
      margin: 30px;
      padding: 25px;
      font-size: 20px;
      border: none;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}

.no-padding-left {
  padding-left: 0;
}
.test-type-dropdown {
  & {
    max-width: 290px;
  }
}

.template-home {
  h2 {
    margin-top: 31px;
  }
  #dropdown-basic {
    & {
      margin-top: 21px;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
}

.text-type-badge {
  & {
    color: #fff !important;
    background-color: #19c797 !important;
    border-color: #19c797 !important;
    float: left;
    padding: 4px 10px;
    border-radius: 7px;
    font-weight: 500;
    margin: 26px;
    opacity: 1;
  }
  &.all {
    color: #f4756e !important;
    background-color: #fee5e4 !important;
    border-color: #f4756e !important;
  }
  &.btoc {
    color: #da9e53 !important;
    background-color: #fcebcc !important;
    border-color: #da9e53 !important;
  }
  &.btob {
    color: #5c98df !important;
    background-color: #e2edf9 !important;
    border-color: #5c98df !important;
  }
  .help-bubble-dropdown {
    & {
      margin-right: 10px;
      margin-left: 0;
    }
  }
  .fa-angle-down {
    & {
      margin-left: 10px;
    }
  }
}

.blured {
  & {
    filter: blur(10px);
  }
}

.requested-type-selector {
  & {
    // max-width: 710px;
    // position: absolute;
    // top: 23%;
    // z-index: 999;
    // left: 50%;
    text-align: center;
    // background: #000000ba;
    padding: 30px;
    border-radius: 20px;
    color: #fff;
    // margin-left: -355px;
  }
  .text-type-badge {
    margin-left: 32%;
    margin-bottom: 24px !important;
  }
  .help-bubble-dropdown {
    cursor: pointer;
  }

  .selector {
    & {
      color: #fff;
      border: none;
      background: #000;
      padding: 10px;
      border-radius: 6px;
      margin: 14px 14px 20px;
      padding: 7 px 20 px !important;
      font-size: 14px !important;
      border: none;
      font-weight: 500;
      padding: 10px 20px;
      border-radius: 25px;
      font-size: 13px !important;
      font-weight: 500;
    }
    &.red {
      color: #f4756e;
      background-color: #fee5e4;
    }
    &.blue {
      color: #5c98df;
      background-color: #e2edf9;
    }
    &.yellow {
      color: #da9e53;
      background: #fcebcc;
    }
  }
}

.nav-error-count {
  & {
    background: #f7bf23;
    width: 22px;
    height: 22px;
    text-align: center;
    margin-left: 5px;
    margin-top: 5px;
    color: #fff;
    font-size: 15px;
    position: absolute;
    line-height: 24px;
    border-radius: 50%;
  }
}

.dropdown-date-filter {
  & {
    margin-top: 18px;
  }
  &:focus {
    outline: none;
  }
  button {
    &,
    &:active,
    &:hover,
    &:active:focus,
    &:focus {
      border: 1px solid #e0e5e9;
      color: #6f7588;
      padding: 7px 20px !important;
      font-size: 14px !important;
      margin-top: 13px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
      outline: none;
    }
  }
  a {
    & {
      float: left;
      width: 100%;
      padding: 10px;
      color: #6f7588;
    }
    &:hover {
      color: #6f7588;
      background: #f3f3f3;
      text-decoration: none;
    }
  }
  .dropdown-menu {
    border-radius: 10px !important;
  }
}

.sub-nav {
  & {
    background: #fff;
    width: 100%;
    padding: 5px;
    margin-top: 36px;
  }
  .ct {
    & {
      width: 1170px;
      margin: 0 auto;
    }
    .account-name {
      & {
        font-size: 22px;
      }
      .status {
        & {
          float: left;
          margin: 12px 12px 0 0px;
        }
        .stat {
          & {
            width: 10px;
            height: 10px;
            float: left;
            border-radius: 50%;
            cursor: pointer;
          }
          &.alive {
            box-shadow: 0px 0px 3px 0px #1ac797;
            background: #1ac797;
          }
          &.ko {
            box-shadow: 0px 0px 3px 1px #ff1d51;
            background: #ff1d51;
          }
          &.suspended {
            box-shadow: 0px 0px 3px 1px #ff701d;
            background: 0px 0px 3px 1px #ff701d;
          }
        }
      }
    }
    ul {
      & {
        list-style: none;
        margin: 0;
      }
      li {
        & {
        }
        a {
          & {
            color: #6f7588;
            font-size: 15px;
            font-weight: 500;
            line-height: 35px;
            padding: 0px 25px;
            cursor: pointer;
          }
          &:hover,
          &:focus {
            color: rgba(0, 0, 0, 0.8);
            text-decoration: none;
          }
          &.active {
            & {
              color: #18164c;
            }
            i {
              color: #3d46fb;
            }
          }
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.card .title {
  padding-bottom: 16px;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #e0e5e9;
}

.row h2 {
  margin-top: 35px;
  float: left;
}

.dropdown-toggle.text-type-badge,
.dropdown-toggle.text-type-badge:hover,
.dropdown-toggle.text-type-badge:focus,
.dropdown-toggle.text-type-badge:focus:active {
  font-size: 12px !important;
  padding: 5px !important;
  margin: 16px 0px 0px 15px !important;
}

.help-bubble {
  margin: 0 10px;
}
.checkups {
  .card {
    .fa-check-circle {
      margin-right: 20px;
      margin-left: 10px;
      color: #2fd059;
      font-size: 30px;
      line-height: 25px;
      float: left;
      cursor: pointer;
    }

    .fa-times-circle {
      margin-right: 20px;
      margin-left: 10px;
      color: #ff1d51;
      font-size: 30px;
      line-height: 25px;
      float: left;
      cursor: pointer;
    }

    .fa-dot-circle {
      margin-right: 20px;
      margin-left: 10px;
      color: #fd8831;
      font-size: 30px;
      line-height: 25px;
      float: left;
      cursor: pointer;
    }
  }

  .btn-header {
    & {
      margin-top: 15px;
    }
  }
  .last-refresh {
    & {
      float: left;
      margin: 30px 30px 0px 0px;
    }
  }
}

.blacklists-modal {
  .red {
    color: #ff1d51;
  }
  .green {
    color: #2fd059;
  }
  img {
    width: 40%;
  }
  p {
    font-size: 17px;
    margin: 25px 8px;
    line-break: auto;
  }
  table {
    tbody {
      & {
        font-size: 17px;
      }
    }
  }
}
p.delete {
  font-size: 14px;
  padding-top: 12px;
}

h2 {
  font-size: 25px;
}

.MuiTooltip-tooltip {
  font-size: 13px !important;
  background-color: #11103b !important;
  padding: 10px !important;
}

.modal-content {
  & {
    border-radius: 10px !important;
  }
}

.btn-secondary {
  background: #ccc;
  border: 1px solid #e0e5e9;
  color: #6f7588;
  background-color: #f4f6f9;
  box-shadow: 0 2px 4px 0 rgb(1 1 2 / 5%);
}

.btn {
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 10px 25px !important;
  border-radius: 30px !important;
}

.modal-body {
  h1 {
    color: #18164c;
  }
}

.reputation-modal .modal-content .txt {
  padding: 20px 0;
}
.reputation-modal .modal-content ul {
  padding-left: 20px;
}

.Toastify__toast--success,
.Toastify__toast--error,
.Toastify__toast--info {
  border-radius: 4px !important;
}

.Toastify__toast--error {
  background: #ff1d51 !important;
}

.format-selector {
  & {
    list-style: none;
  }
  li {
    & {
      float: left;
      margin-right: 10px;
      font-size: 12px !important;
      padding: 8px 20px !important;
      margin-top: -8px;
    }
    &.selected {
      background-color: #3d46fb;
      border-color: #3d46fb;
      color: #fff;
    }
  }
}

a.grey {
  & {
    color: #757f8b !important;
  }
}

.alert.bounce {
  background: #ff7b1d !important;
}
.alert.status {
  background: #ff1d51;
  color: #fff;
  font-size: 15px;
}
.blacklists-modal .alert.warning {
  margin-bottom: 45px;
  padding: 20px 7px;
}
.alert.status .btn {
  background: #0000007d;
}
.alert.status .btn:hover {
  opacity: 0.85;
}
.alert.status .btn {
  color: #fff;
  font-size: 15px !important;
  margin-top: -10px;
}
.alert.bounce a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

footer {
  & {
    padding: 15px 10px 35px;
  }
}

footer a {
  color: #757f8b !important;
  margin-left: 10px;
  margin-right: 10px;
}

.overlay {
  & {
    background: #00000082;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
  }
}

.pop-warmer {
  & {
    position: fixed;
    background: #fff;
    left: 43%;
    top: 29%;
    font-size: 15px;
    width: 450px;
    padding: 60px 60px 30px;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
  .fa-times {
    & {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      opacity: 0.6;
      font-size: 19px;
    }
  }
  .btn.btn-primary {
    & {
      margin: 10px 0;
      margin-bottom: 15px;
    }
    &:hover {
      background-color: #26c180;
      opacity: 0.9;
    }
  }
  h1 {
    & {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  img {
    & {
      width: 40%;
      margin-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .pop-warmer {
    & {
      left: 4%;
      width: 92%;
    }
  }
}

.spf-test {
  & {
    & {
      padding-top: 45px !important;
    }
  }

  .missing-spf-record {
    h2 {
      & {
        margin-bottom: 21px !important;
      }
    }

    .no-padding-left {
      & {
        padding-left: 0;
      }
    }
  }

  .upgrade-spamchecker {
    .insight-content {
      h2 {
        padding-left: 0;
      }
      & {
        height: auto;
      }
    }
  }

  .green-bck.invalid-btn {
    & {
      margin-top: 7px;
    }
    &:hover {
      background-color: #26c180;
    }

    &.missing-btn {
      & {
        margin-top: 5px;
      }
    }
  }

  .record-info {
    .insight-content {
      h2 {
        & {
          background-color: transparent;
          border-bottom: 1px solid #e0e5e9;
          width: 100%;
          padding: 8px 0 10px;
          margin-top: -4px;
          font-size: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }

  .valid-record {
    .record-value {
      & {
        margin-top: 6px;
      }
    }
    .txt-desc-spf {
      & {
        margin-top: 12px;
      }
    }
  }

  .invalid_record {
    h2 {
      & {
        margin-bottom: 27px !important;
      }
    }
  }

  .insight-content {
    & {
      height: 161px;
    }
    h2 {
      padding-left: 0;
      padding-top: 5px;
      font-size: 25px;
    }

    h2.little-txt {
      & {
        padding: 41px 15px;
      }
    }

    .txt-desc-spf {
      & {
        padding-left: 18px;
      }
    }

    p.record-value {
      & {
        margin: 17px 13px;
        margin-top: 0;
        padding: 5px 15px;
        border-radius: 5px;
        background: #f1eeee;
      }
    }
  }
}

// MODAL CSS
.modal.show {
  opacity: 1;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
