@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.ml-score-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  color: var(--theme-text);
  border-color: var(--border-color);
  max-width: 400px;
  padding: 2rem;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 320px;
  }

  .ml-score-bg-mask {
    position: absolute;
    inset: 0;
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: center top;
    pointer-events: none;
    mask-image: linear-gradient(180deg, rgba(4, 4, 16, 0) 0%, #040410 100%);
  }

  .ml-score-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    h3 {
      margin: 0;
      font-size: 22px;
      color: #11103c7f;
    }

    > i,
    > i > svg {
      width: 20px;
      height: 20px;
      color: #11103c7f;

      &:hover {
        color: #11103c;
      }
    }
  }
  .ml-score-content {
    @include flex-items-center;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;

    @media (max-width: 768px) {
      > div {
        width: 150px !important;
        height: 150px !important;
      }
    }
  }

  > p {
    color: #040410b9;
    font-size: 15px;
    line-height: 1.5;
    text-align: left;
  }
}
