// circular pattern

.circular-pattern {
  display: grid;
  width: max(99vw, 99vh);
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  isolation: isolate;
  pointer-events: none;
  z-index: 1;
  max-width: 1800px;
  transform: translate(-50%, -25%);

  @media (max-width: 860px) {
    max-width: 99vw;
    overflow: hidden;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    border-radius: 999px;
    border: 1px solid rgba(4, 4, 16, 0.07);
  }

  > * {
    grid-area: 1 / 1;
    flex-shrink: 0;
  }

  [class*="circle-"] {
    position: relative;
    width: 100%;
    border-radius: 999px;
    border: 1px solid rgba(4, 4, 16, 0.07);
    aspect-ratio: 1 / 1;
    height: auto;
    align-self: center;
    justify-self: center;

    &:nth-child(1) {
      width: 80%;
    }
    &:nth-child(2) {
      width: 60%;
    }
    &:nth-child(3) {
      width: 40%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }

  & ~ * {
    position: relative;
    z-index: 20;
  }
}
