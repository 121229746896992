@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.ml-customer-stories-container {
  position: relative;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .ml-customer-stories-header {
    @include flex-items-center-justify-between;
    margin-bottom: 2.5rem;

    h3 {
      font-size: 24px;
      font-weight: 500;
      color: #000;
      margin: 0;
      line-height: 1.2;
      color: var(--theme-text);
    }
    .ml-customer-stories-logo {
      display: inline-block;
      flex-shrink: 0;
      img {
        height: 100%;
        max-height: 28px;
        object-fit: contain;
      }
    }
  }
}

.carousel {
  position: relative;
  flex-grow: 1;
}

.slides-wrapper {
  width: 100%;
  overflow: hidden;
}

.slides-track {
  display: flex;
  transition: transform 0.6s ease;
  width: 100%;
}

.slide {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  height: 100%;
}

.slide-content {
  padding: 1rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:focus-within {
    opacity: 1;
  }
}

.rating {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  .star > svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.quote {
  margin-top: 1.5rem;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  color: var(--theme-text);

  strong,
  b {
    font-weight: 500 !important;
  }
}

.author {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-top: 2rem;

  @media (max-width: 480px) {
    gap: 0.75rem;

    .ml-testimonials-avatar {
      width: 36px;
      height: 36px;
    }
    .author-info {
      .title {
        margin: 0 !important;
      }
    }
  }
}

.ml-testimonials-avatar {
  width: 48px;
  height: 48px;
  border-radius: 999px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 999px;
  }
}

.author-info {
  .name {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: var(--theme-text);
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    color: #04041098;
    margin: 0.25rem 0 0;
  }
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 1px solid #eee;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 10;

  &:hover:not(:disabled) {
    background: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  span {
    font-size: 20px;
    color: #333;
  }

  &.prev {
    left: -20px;
  }

  &.next {
    right: -20px;
  }
}

// Optional fade effect for content
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-content {
  animation: fadeIn 0.5s ease-out;
}

.testimonials-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem;

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    background: white;
    border: 1px solid var(--theme-blue);
    cursor: pointer;
    font-size: 20px;
    color: var(--theme-blue);
    transition: ease all 0.3s;

    &:hover {
      opacity: 1;
      color: #fff;
      background-color: var(--theme-blue);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    bottom: 1rem;
    right: 1rem;
    gap: 0.5rem;

    button {
      width: 32px;
      height: 32px;
    }
  }
}
