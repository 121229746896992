.ml-icon-box {
  --icon-size: 40px;
  --icon-bg-color: #fff;
  --icon-color: #11103c;
  --icon-radius: 8px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: var(--icon-radius, 8px);
  background-color: var(--icon-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon--color);
  flex-shrink: 0;

  svg,
  img {
    --icon-svg-size: calc(var(--icon-size)-16px);
    width: var(--icon-svg-size);
    height: var(--icon-svg-size);
    color: var(--icon-color);
  }
}
