@import "../../../assets/styles/main.scss";

html,
body {
  background: #f7f5ff;
  max-width: 100vw;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title);
}

.spamtest-results-page,
.loader,
.content-container {
  font-family: var(--font-text);
  --header-nav-height: 60px;
  --header-nav-spacing: 40px;
  --offer-banner-height: 0px;
  padding-top: calc(var(--header-nav-height, 50px) + var(--header-nav-spacing, 30px) + var(--offer-banner-height, 0px)) !important;
  margin-top: 0 !important;
}
.with-offer-banner .spamtest-results-page,
.with-offer-banner .loader,
.with-offer-banner .content-container {
  --offer-banner-height: 70px;

  @media (max-width: 800px) {
    --offer-banner-height: 120px;
  }

  @media (max-width: 600px) {
    --offer-banner-height: 140px;
  }
}
.app.spamtest-results {
  min-height: calc(99vh - var(--header-nav-height, 50px));
}
.spam-results-modal tr td {
  border-color: var(--card-border-color) !important;
}
.table-results tr td:first-child {
  padding-left: 0 !important;
}
.table-results tr td:last-child {
  padding-right: 0 !important;
}
.table-results .hour {
  padding-top: 14px !important;
}

.test-page-header {
  @include flex-items-center-justify-between;
  margin-bottom: 48px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2rem;
  }

  h1 {
    font-size: 28px;
    margin: 0;
    font-weight: 500;
    line-height: 1;
    color: var(--theme-text);
    font-family: var(--font-title);
  }
}

.test-results-actions {
  @include flex-items-center-justify-between;

  .mail-cion {
    display: none;
    --padding-x: 0;
    --padding-y: 0;
    width: 32px;
    height: 32px;
  }

  @media (max-width: 630px) {
    .text-action-buttons {
      .button:last-child {
        display: none;
      }
    }
  }

  @media (max-width: 420px) {
    .text-action-buttons {
      .button:not(.mail-cion) {
        display: none;
      }
      .mail-cion {
        display: flex;
      }
    }
  }

  .test-action-title,
  .text-action-buttons {
    @include flex-items-center;
  }
  .text-action-buttons {
    gap: 1.5rem;
  }
  .test-action-title {
    gap: 2rem;

    @media (max-width: 800px) {
      gap: 1rem;
    }
    h2 {
      margin: 0;
      font-weight: 500;
      line-height: 1;
      color: var(--theme-text);
      font-size: 24px;
    }
    [aria-busy="true"] {
      height: 24px;
      width: 24px;
    }
  }
}

.ml-sub-header {
  @include flex-items-center-justify-between;

  .ml-sub-header-title,
  .ml-sub-header-content {
    @include flex-items-center;
  }
  .ml-sub-header-content {
    gap: 1.5rem;
    justify-content: flex-end;

    > p {
      margin: 0;
      font-weight: 500;
      line-height: 1;
      color: var(--theme-text);
      font-size: 16px;
    }

    .copy-text {
      display: none;
      @media (min-width: 420px) and (max-width: 800px) {
        display: block;
      }
    }

    .copy-text-mini {
      display: none;
      @media (max-width: 419px) {
        display: block;
      }
    }

    .share-text {
      @media (max-width: 800px) {
        display: none;
      }
    }

    .copy-link {
      @include flex-items-center;
      align-items: stretch;
      gap: 0.5rem;
      padding: 0;
      border: 1px solid #cdcdcf;
      background-color: var(--theme-bg);
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;

      .code {
        font-size: 16px;
        color: #04041080;
        font-weight: 500;
        line-height: 1;
        padding: 1rem;
      }
      .copy {
        @include flex-items-center;
        padding: 0 1rem;
        color: #040410;
        border-left: 1px solid #cdcdcf;
        aspect-ratio: 1 / 1;
        background-color: transparent;
        overflow: hidden;
        &:hover {
          background-color: #fff;
        }
        svg {
          width: 24px;
          height: 24px;
          color: #11103b;
        }
      }

      @media (max-width: 800px) {
        .code {
          display: none;
        }
        .copy {
          border-left: 0;
        }
      }
    }
  }
  .ml-sub-header-title {
    gap: 2rem;
    h2 {
      margin: 0;
      font-weight: 500;
      line-height: 1;
      color: var(--theme-text);
      font-size: 24px;
    }
  }
}

.ml-table-td-center {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
}
td {
  vertical-align: middle !important;
  &.inbox-type {
    vertical-align: middle;
    > div {
      @include flex-items-center;
      gap: 1rem;
      justify-content: flex-start;
      height: 100%;
    }

    @media (max-width: 860px) {
      .ml-badge.default {
        display: none;
      }
    }
  }
}

.ml-analyzer-info {
  @include flex-items-center-justify-between;
  gap: 1rem;
  width: 100%;
  padding: 1.65rem 0;
  border-radius: 0px;
  border-bottom: 1px solid var(--border-color);

  @media (max-width: 800px) {
    padding: 1.2rem 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    .button {
      margin-left: 24px;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0.5rem;
  }

  &:first-child {
    padding-top: 0.5rem;
  }

  p {
    margin: 0;
    font-weight: 500;
    line-height: 1;
    color: var(--theme-text);
    font-size: 16px;
    opacity: 70;

    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }

  .button {
    --icon-size: 18px !important;
    gap: 0.5rem;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .analyzer-info-content {
    @include flex-items-center;
    gap: 1rem;
    justify-content: flex-start;
    text-align: left;

    svg,
    i {
      width: 24px;
      height: 24px;
    }

    i:not(.fas) {
      cursor: pointer;
    }

    .ml-analyzer-info-tooltip,
    .ml-analyzer-info-tooltip i {
      width: 14px;
      height: 14px;
      @include flex-items-center;
      justify-content: center;
    }

    .lucide-circle-check {
      color: var(--theme-green);
      stroke: var(--theme-green);
    }
    .lucide-circle-dot {
      color: var(--theme-yellow);
      stroke: var(--theme-yellow);
    }
    .lucide-circle-x {
      color: var(--theme-red);
      stroke: var(--theme-red);
    }
  }
}

.ml-analyzer-card {
  margin: 2.5rem 0;
  display: block;
  width: 100%;
  float: left;
  border-radius: 16px;
  border: 1px solid #0404100f;
}

.ml-stats-review {
  display: flex;
  gap: 2rem;
  align-items: stretch;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.ml-results-info {
  display: flex;
  gap: 3rem;
  align-items: stretch;

  @media (max-width: 800px) {
    gap: 1.5rem;
  }
}

.more-info {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;

  a {
    font-size: 16px;
    color: #11103b6f;
    font-weight: 400;
    line-height: 1;
    font-family: var(--font-text);
  }
}

@media (max-width: 1000px) {
  .spamtest-results-page {
    width: 100% !important;

    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .container {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      > div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 860px) {
  .table-results .inbox-type {
    width: auto;
  }
  .table-results .hour {
    display: none;
  }
}
@media (max-width: 630px) {
  .table-results .inbox-type {
    display: none;
  }
  .checkups {
    padding-right: 0 !important;
  }
}

@media (max-width: 640px) {
  .ml-results-info {
    flex-direction: column;
  }
  .ml-score-info {
    width: 100%;
    max-width: 100% !important;
  }
}

@media (max-width: 480px) {
  .ml-sub-header .ml-sub-header-title h2,
  .test-results-actions .test-action-title h2 {
    font-size: 20px;
  }
  .spam-results-modal tr td:last-child {
    vertical-align: top !important;
  }

  .spamtest-results-page {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

@media (max-width: 800px) {
  .stats-container,
  .ml-customer-stories-container {
    padding: 2rem !important;
  }
}

.app .sidebar {
  z-index: 150;
}

.animate-spin {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
