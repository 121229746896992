@import "../../../assets/styles/variables";

.button {
  --padding-x: 1.75rem;
  --padding-y: 1rem;

  --bg-color: transparent;
  --border-color: transparent;
  --text-color: var(--theme-blue);
  --font-weight: 500;

  --icon-size: 1.5rem;
  text-decoration: none;

  display: inline-flex;
  flex-shrink: 0;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  padding: var(--padding-y) var(--padding-x);
  border-radius: 999px;
  font-weight: var(--font-weight, 500);
  border: 1px solid var(--border-color);
  cursor: pointer;
  line-height: 1;
  color: var(--text-color);
  background-color: var(--bg-color);
  transition: ease all 0.3s;
  font-family: var(--font-text);

  &:has(svg) {
    --padding-x: 1.5rem;
  }

  &:hover,
  &:focus {
    --bg-color: var(--theme-gray);
    --border-color: var(--theme-gray);
    outline: none;
    color: var(--text-color);
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(#5c98df, 0.4);
  }

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    color: inherit;
  }

  &.primary {
    --bg-color: var(--theme-blue);
    --text-color: white;
    --border-color: var(--theme-blue);
    &:hover {
      --bg-color: var(--theme-blue-hover);
    }
  }

  &.secondary {
    --bg-color: var(--theme-green);
    --text-color: white;
    --border-color: var(--theme-green);
    &:hover {
      --bg-color: var(--theme-green-light);
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(#27b970, 0.4);
    }
  }

  &.outline {
    --text-color: var(--theme-blue);
    --border-color: var(--theme-blue);
    &:hover {
      --bg-color: var(--theme-gray);
      --border-color: var(--theme-blue);
      --text-color: var(--theme-blue);
    }

    &.light {
      --bg-color: transparent;
      --border-color: #fff;
      --text-color: #fff;
      &:hover {
        --bg-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &.danger {
    background-color: var(--theme-red);
    border-color: var(--theme-red);
    color: #ffffff;
    &:hover {
      background-color: var(--theme-red-light);
      border-color: var(--theme-red-light);
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(#f1472d, 0.4);
    }
  }

  &.link {
    --text-color: var(--theme-green);
    --padding-x: 1rem;
    --padding-y: 0.25rem;
    border: 0;
    text-decoration: underline;
    text-underline-offset: 3px;
    background-color: transparent;
    &:hover {
      --bg-color: transparent;
      --text-color: var(--theme-green-light);
      --border-color: var(--theme-green);
    }
    &:focus {
      --bg-color: transparent;
      --border-color: var(--theme-green);
      outline: none;
      color: var(--text-color);
      text-decoration: none;
      box-shadow: 0 0 0 0.25rem rgba(#27b970, 0.4);
    }
  }

  &.sm {
    font-size: 12px;
    --padding-x: 1.25rem;
    --padding-y: 0.75rem;
  }
}
