// Tailwind-Like Spacing Scale
$spacing: (
  0: 0px,
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  6: 24px,
  8: 32px,
  10: 40px,
  12: 48px,
  16: 64px,
  20: 80px,
  24: 96px,
  32: 128px,
  40: 160px,
  48: 192px,
  56: 224px,
  64: 256px,
);

// Margin and Padding Directions
$spacing-directions: (
  "": null,
  // General (m-1, p-2)
  "t": top,
  // mt-1, pt-2
  "r": right,
  // mr-1, pr-2
  "b": bottom,
  // mb-1, pb-2
  "l": left,
  // ml-1, pl-2
);

// Generate Classes for Margin and Padding
@each $key, $value in $spacing {
  // Single direction margin & padding
  @each $short, $long in $spacing-directions {
    .m#{$short}-#{$key} {
      @if $long {
        margin-#{$long}: $value;
      } @else {
        margin: $value;
      }
    }
    .p#{$short}-#{$key} {
      @if $long {
        padding-#{$long}: $value;
      } @else {
        padding: $value;
      }
    }
  }

  // Handle mx-* and my-* separately
  .mx-#{$key} {
    margin-left: $value;
    margin-right: $value;
  }
  .my-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }
  .px-#{$key} {
    padding-left: $value;
    padding-right: $value;
  }
  .py-#{$key} {
    padding-top: $value;
    padding-bottom: $value;
  }

  // 🔹 Space Between Children (space-x & space-y)
  .space-x-#{$key} > * + * {
    margin-left: $value;
  }
  .space-y-#{$key} > * + * {
    margin-top: $value;
  }
}
