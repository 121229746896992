@import "../button/button.scss";

.ml-tabs {
  margin: 0;
  padding: 0;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  list-style: none;
  gap: 0.25rem;
  border-radius: 999px;
  background-color: #fff;
  padding: 2px;
  overflow: hidden;

  @media (max-width: 500px) {
    flex-direction: column;
    border-radius: 24px;
    width: 100%;
    padding: 5px;
    border: 1px solid #04041016;

    li,
    .button {
      width: 100%;
    }
  }

  .ml-tab-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 999px;
    background-color: var(--theme-yellow);
    margin-left: 0.25rem;
  }
}
