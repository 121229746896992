@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.ml-offer-banner {
  --bg-color: #26a0fe;

  &.primary {
    --bg-color: var(--theme-blue);
  }

  &.indigo {
    --bg-color: var(--theme-indigo);
  }

  width: 100%;
  padding: 1.5rem 2rem;
  padding-right: calc(2rem + 2.5rem);
  background-color: var(--bg-color);
  color: #ffffff;
  position: relative;

  @media (max-width: 800px) {
    padding: 1rem;
    padding-right: 4rem;
    .ml-offer-banner-container {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start !important;
      justify-content: flex-start !important;

      .ml-offer-banner-content {
        align-items: flex-start;
        justify-content: flex-start;
      }

      .ml-offer-banner-cta {
        margin-left: 5rem;
      }
    }
    .ml-offer-banner-close {
      top: 24px !important;
    }
  }

  .ml-offer-banner-container {
    @include flex-items-center-justify-between;
    gap: 1rem;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }

  .ml-offer-banner-content {
    @include flex-items-center;
    gap: 1.5rem;
    width: 100%;
  }

  .ml-icon-box {
    svg {
      color: var(--bg-color);
    }
  }

  .ml-offer-banner-title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .ml-offer-banner-message {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .ml-offer-banner-cta {
    @include flex-items-center;
    gap: 1rem;
    width: auto;
    flex-shrink: 0;
  }

  .ml-offer-banner-close {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: #ffffff;
      fill: currentColor;
    }
  }
}

.ml-blue-banner {
  background-color: var(--theme-blue);
  color: #ffffff;
  padding: 2rem;
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--theme-blue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  &.light {
    background-color: #fff;
    color: var(--theme-text);
    border-color: var(--border-color);

    .ml-icon-box {
      --icon-bg-color: #27b97033;
      --icon-size: 48px;
      --icon-radius: 10px;
      margin-bottom: 2rem;
      svg {
        color: var(--theme-green);
      }
    }
  }

  .button {
    font-size: 15px;
    margin-top: 1rem;
  }

  h3 {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 1rem;
    max-width: 600px;
    line-height: 1.3;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    line-height: 26px;
    max-width: 100%;
    max-width: 700px;

    &.highlight {
      font-weight: 700;
    }
  }
}

.ml-cta-banner {
  @include flex-items-center-justify-between;
  gap: 1rem;
  width: 100%;
  background-color: var(--theme-blue);
  color: #ffffff;
  padding: 4rem 5rem;
  position: relative;
  border-radius: 12px;

  @media (max-width: 800px) {
    padding: 3rem;
    flex-direction: column;
  }

  .button {
    font-size: 16px;
    margin-top: 2rem;
  }

  p {
    font-size: 3.25rem;
    font-weight: 500;
    overflow: hidden;
    line-height: 1.3;
    max-width: 500px;
    font-family: var(--font-title);

    em {
      font-style: normal;
      opacity: 0.7;
    }
  }
}

.ml-cta-image-banner {
  @include flex-items-center-justify-between;
  gap: 1rem;
  width: 100%;
  background-color: var(--theme-blue);
  color: #ffffff;
  padding: 4em 5rem;
  position: relative;
  border-radius: 12px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 4rem;

    .ml-cta-banner-content {
      width: 100%;
    }

    .ml-cta-banner-image {
      margin-top: 2rem;
      max-width: 600px !important;
    }
  }

  @media (max-width: 640px) {
    padding: 3rem 3rem;
  }

  .ml-cta-banner-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
  }

  h3 {
    font-size: 28px;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    overflow: hidden;
    line-height: 28px;
    max-width: 500px;
    color: rgba(255, 255, 255, 0.8);

    &.highlight {
      font-weight: 700;
      color: #fff;
    }
  }

  .button {
    font-size: 16px;
    margin-top: 2rem;
  }

  .ml-cta-banner-image {
    max-width: 400px;
    padding: 2rem;

    img {
      width: 100%;
    }
  }
}
