:root {
  --theme-bg: #f7f5ff;
  --theme-text: #040410;
  --theme-blue: #11103c;
  --theme-blue-hover: #11103c;
  --theme-green: #27b970;
  --theme-indigo: #8c62ff;
  --theme-green-light: #1e9f5e;
  --theme-yellow: #e8b500;
  --theme-gray: #ffffff;
  --theme-red: #fb5339;
  --theme-red-light: #df3f26;

  --badge-gray: rgba(#11103c, 0.1);
  --badge-blue: #5c98df;
  --badge-yellow: #da9e53;
  --badge-red: #d83b22;
  --badge-green: #008c53;

  --font-family: "Montserrat", sans-serif;

  --spacing: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;

  --border-radius: 8px;
  --border-radius-2x: 16px;

  --border-color: #0404100d;
  --card-border-color: #04041012;

  --font-title: "TWK Lausanne", "Montserrat", sans-serif;
  --font-text: "SFProDisplay", "Montserrat", sans-serif;
}
